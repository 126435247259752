<template>
<div class="list-widget last-post list-dash-items" :widget-id="widget.id" :widget-type="widget.type">
    <div v-if="widgetLoad" class="tgju-widget-content full-padding">
        <div class="text-center p-2">
            <div class="widget-loading"></div>
        </div>
    </div>
    <slot v-else-if="posts.length">
        <div v-for="post in posts" :key="post.id" class="list-row d-block hover">
            <router-link :to="post.user.username != $store.state.user.username ? { name: 'ProfileOthers', params: { username : post.user.username }, } : '' " class="link-title">
                <div v-html="post.content"></div>
            </router-link>
            <div class="link-items">
                <i class="uil uil-user link-item-icon"></i> <span class="badge badge-primary"> {{ post.user ? post.user.username : post.name}}</span>
                <span class="time">{{ toRelative(post.created_at) }}</span>
            </div>
        </div>
    </slot>
    <div v-else class="text-center p-2">
        <div class="not-found-items">
            <i class="uil uil-exclamation"></i>
            مقداری یافت نشد.
        </div>
    </div>
</div>
</template>

<style lang="scss" scoped>
.show-widget-settings-modal {
    display: flex;
    margin-left: 0;
    margin-right: auto;
}
</style>

<script>
// این کامپوننت دارای اجزا و کنترلر های نمایش آخرین نظرات در ویجت داشبورد می باشد
import moment from "moment-jalaali";

export default {
    name: 'FriendPosts',
    props: ['widget', 'data', 'editable', 'wrapper'],
    components: {},
    data() {
        return {
            posts: {
                id: undefined,
                length: undefined,
                created_at: undefined,
                user: {
                    username: undefined,
                },
            },
            responsive: {
                rightSection: true,
                leftSection: false,
            },
            widgetLoad: true,
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            if (Object.keys(newValue).length && (typeof oldValue == 'undefined' || !Object.keys(oldValue).length)) {
                this.getLastPost();
            }
        }, { immediate: true });
    },
    methods: {
        // این متد جهت نمایش مناسب زمان مورد استفاده قرار میگیرد
        toRelative(date) {
            return moment(date).fromNow();
        },
        // این متد جهت دریافت آخرین نظرات از سرور مورد استفاده قرار میگیرد
        getLastPost() {
            let api_data = {};

            this.$helpers.makeRequest("GET", "/post/friend-posts", api_data)
                .then((api_response) => {
                    this.$helpers.api_alert(api_response);

                    if (api_response.data.response.status === "success") {
                        this.posts = api_response.data.response.posts;
                        this.widgetLoad = false;
                    }
                });
        },
        // این متد وظیفه بازکردن مودال و هندل کردن کال بک مودال تنظیمات را بر عهده دارد
        open_settings_modal() {
            let callbackEvent = this.$router.currentRoute.name + '_save_settings_' + this.widget.id + '_' + new Date().getTime();

            let modal_data = {
                modal: 'widget_settings_modal',
                callbackEvent,
                component: 'Settings/MyPosts.vue',
                config: {
                    data: {
                        title: this.widget.title,
                    }
                }
            };

            modal_data = this.$helpers.unbindObject(modal_data);

            this.$root.$emit('open_modal', modal_data);

            this.$root.$on(callbackEvent, (settings) => {
                this.widget.title = settings.title;

                let widget = this.$helpers.unbindObject(this.widget);

                this.$helpers.saveWidget(widget);
            });
        },
        makeResponsive(mode) {
            if (mode == 1) {
                this.responsive.rightSection = true;
                this.responsive.leftSection = false;
            } else if (mode == 2) {
                this.responsive.rightSection = false;
                this.responsive.leftSection = true;
            } else if (mode == 3) {
                this.responsive.rightSection = false;
                this.responsive.leftSection = false;
            }
        },
    },
}
</script>
